// @flow
import React from 'react';
import SEO from '../Seo/Seo.js';


const Layout = ({ children, customSEO }) => (
  <div>
    {!customSEO && <SEO/>}
    {children}
  </div>
);

export default Layout;
