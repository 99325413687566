// @flow
import React, { useState, useEffect } from 'react';
import styles from './Header.module.scss';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import MailChimpWrapper from '../MailChimpWrapper';
import useCategoriesList from '../../hooks/use-categories-list';


const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isCategoriesMenuActive, setIsCategoriesMenuActive] = useState(false);
  const categories = useCategoriesList();

  useEffect(() => {
    setIsMenuActive(false);
    setIsCategoriesMenuActive(true);
  }, []);
  
  return (
    <div className={`${styles['header__wrapper']}`}>
      <nav className={`navbar is-transparent is-fixed-top ${styles['header__nav']}`}>
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="/media/header.png" alt="Guriosity" />
          </a>
          <div className="navbar-burger burger"
            data-target="navbarExampleTransparentExample"
            onClick={() => setIsMenuActive(!isMenuActive)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div
          className={`navbar-menu ${styles['header__navbar-menu']} ${isMenuActive ? 'is-active': ''}`}>
          <div className="navbar-start">
            <Link className={`navbar-item ${styles['header__item']}`} to="/about/">
              <span className='is-hidden-desktop'>👱</span> About
            </Link>
            <Link className={`navbar-item ${styles['header__item']}`} to="/blog/">
              <span className='is-hidden-desktop'>🖊️</span> Blog
            </Link>
            <a className={`navbar-item ${styles['header__item']}`} href="https://forms.gle/vvdgDfjds4ETZTxi7" target='_blank' rel='noopener noreferrer'>
              <span className='is-hidden-desktop'>➕</span> Submit
            </a>
            <div className="navbar-item has-dropdown is-hoverable is-hidden-tablet">
              <a className={`navbar-link ${styles['header__item']}`} onClick={() => setIsCategoriesMenuActive(!isCategoriesMenuActive)}>
                📁 Categories
              </a>
              <div className={`navbar-dropdown ${styles['header__dropdown']} ${isCategoriesMenuActive ? 'is-hidden': ''}`}>
                {categories.map((category) => (
                  <Link key={category.fieldValue} className={`navbar-item ${styles['header__item-category']}`} to={`/${kebabCase(category.fieldValue.toLowerCase())}`}>
                    {category.fieldValue}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <div className={`navbar-item ${styles['header__cta']}`}>
              <p className={`is-hidden-desktop ${styles['header__cta-pitch']}`}>
                Get a weekly digest of tech articles. No spam.
              </p>
              <MailChimpWrapper/>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;