// @flow
import { useStaticQuery, graphql } from 'gatsby';

const _ = require('lodash');

const useArticleCategoriesList = () => {
  const { categories } = useStaticQuery(
    graphql`
      query {
        categories: allGoogleSpreadsheetBlogsArticles {
          group(field: category) {
            fieldValue
            totalCount
          },
        }
      }
    `
  );

  return _.orderBy(categories.group.filter(g => g.fieldValue), ['totalCount'],['desc']);
};

export default useArticleCategoriesList;
