// @flow
import React from 'react';
import CookieConsent from "react-cookie-consent";

const CookieConsentWrapper = () => (
  <CookieConsent
    containerClasses="cookies"
    buttonText="I understand"
    declineButtonText="Privacy Policy"
    disableStyles
    disableButtonStyles
    hideOnDecline={false}
    setDeclineCookie={false}
    onDecline={() => {window.location.replace('/privacy')}}
    enableDeclineButton
    style={{ background: "rgba(255,255,255,0.9)", color: "grey", fontSize: "12px", textAlign:  "center", left: "0px", position: "fixed", width: "100%", zIndex: "999", bottom: "0px", padding: "10px"}}
    buttonStyle={{ background: "grey", color: "whitesmoke", fontSize: "12px", fontWeight: 500, border: "0px", padding: "5px 10px", margin: "5px 0px 0px 5px", cursor: "pointer"}}
    declineButtonStyle={{background: "white", color: "grey", textDecoration: "underline", border: "0", cursor: "pointer"}}
  >
    By using our website, you accept that we use cookies.
  </CookieConsent>
)

export default CookieConsentWrapper;