// @flow
import React from 'react';
import Mailchimp from 'react-mailchimp-form';
import styles from './MailChimpWrapper.module.scss';


const MailChimpWrapper = () => (
  <Mailchimp
    className={`${styles['mailchimp']}`}
    action='https://guriosity.us17.list-manage.com/subscribe/post?u=81d3414cad42c7555546543eb&id=881ed009d1'
    fields={[
      {
        name: 'EMAIL',
        placeholder: '✉️ Enter your email',
        type: 'email',
        required: true
      }
    ]}
    messages = {
      {
        sending: "Subscribing...",
        success: "Almost there... Check your email to confirm.",
        error: "An unexpected internal error has occurred.",
        empty: "You must write an email.",
        duplicate: "You were already subscribed to this list.",
        button: "STAY UPDATED"
      }
    }
    styles = {
        {sendingMsg: {
          color: "orange"
        },successMsg: {
          color: "orange"
        },duplicateMsg: {
          color: "#EE5A24"
        }, errorMsg: {
          color: "#ED4C67"
        }
      }
    }
  />
)

export default MailChimpWrapper;